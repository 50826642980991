.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    .left {
        margin-right: 12px;
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        display: flex;
        align-items: center;

        .logo {
            width: 36px;
            height: 36px;
            margin-right: 12px;
        }
    }

    .userInfo {
        color: #fff;

        .name {
            cursor: pointer;
            font-size: 18px;
        }
    }
}

.leftSider {
    height: calc(100vh - 64px);
    overflow: hidden;
}

.content {
    padding: 12px;
    margin: 0px;
    // min-height: 280px;
    background-color: #fff;
    height: calc(100vh - 132px);
    // max-height: calc(100vh - 140px);
    border-radius: 4px;
    box-sizing: border-box;
    //   overflow-y: scroll;
    overflow: hidden;
}

.footer {
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
